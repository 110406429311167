export class Accesorio {
    constructor(
        public IdAccesorio: number | string = '',
        public descripcion: string = '',
        public importe?: number, // Importe teórico
        public importeAsignado: number = 0.00, // Importe realmente usado,
        public precioIncluido: boolean = false, // true si en vez del precio mostramos "Incluido"
        public precioDescripcion: string = "", // descripcion del precio, solo cuando precioIncluido
        public cantidad: number = 1
    ) { }
}
