export class Imagen {
    IdImagen: number | string;
    src: string; // url de la imagen (base 64)
    nombre: string; // nombre del archivo
    orden: number; // orden de la imagen en un grupo de imágenes
    destacada: boolean; // saber si la imagen es destacada entre un grupo de imagenes
    titulo: string; // titulo de la imagen
    tipo: string; // tipo, puede ser extensión de la imagen o "youtube"

    constructor(
        IdImagen: number,
        src: string,
        orden: number,
        destacada: boolean,
        titulo: string = '',
        tipo: string = ''
    ) {
        this.IdImagen = IdImagen;
        this.src = src;
        this.orden = orden;
        this.destacada = destacada;
        this.titulo = titulo;
        this.tipo = tipo;
    }
}